import methods from '@/enums/methods';
import { ramses as api } from '../api';
import thoth from '../api/thoth';
import Thoth from '../base/thoth';

import store from '@/store';

const BASE = 'veripag/liquidation';

const TIME_IN_MINUTE = 10 * 60 * 1000; // 10 minutes

const username = store.getters['user/user'].data.name;

const showLiquidation = async () => {
    try {
        return await api.get(BASE);
    } catch ({ response }) {
        return response;
    }
};

const liquidation = async () => {
    try {
        return await api.post(
            BASE,
            {},
            {
                timeout: TIME_IN_MINUTE,
            },
        );
    } catch ({ response }) {
        return response;
    }
};

const installments = async date => {
    try {
        return await thoth.post('upload/updateInstallments', {
            username,
        }, {
            params: {
                date,
            },
        });
    } catch (err) {
        if (err.response?.status === 400) {
            return err.response;
        }

        return {
            status: 200,
        };
    }
};

const extractConciliation = async date => {
    try {
        return await thoth.post('upload/extractConciliation', {
            username,
        }, {
            params: {
                date,
            },
        });
    } catch (err) {
        return err.response;
    }
};

const reconciliation = async date => {
    try {
        return await thoth.post('reconciliation', {
            username,
        }, { params: { date } });
    } catch ({ response }) {
        return response;
    }
};

const checkingTransaction = async date => {
    try {
        return await thoth.post('checkingTransactions', { date, username });
    } catch ({ response }) {
        return response;
    }
};

class LiquidationService extends Thoth {
    extractVeripag({ date }) {
        return this.api({
            url: '/extract-veripag',
            data: { username },
            params: {
                date,
            },
            method: methods.POST,
        });
    }

    liquidationVeripag({ date }) {
        return this.api({
            url: '/liquidation-veripag',
            data: { username },
            params: {
                date,
            },
            method: methods.POST,
        });
    }
}

export default new LiquidationService();

export {
    showLiquidation, liquidation, installments, extractConciliation, reconciliation, checkingTransaction,
};
