<template>
  <b-container>
    <b-card>
      <validation-observer
        ref="loginValidation"
        #default="{ invalid }"
      >
        <b-form @submit.prevent>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Data"
                label-for="initialDate"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Data"
                  rules="required"
                >
                  <b-form-input
                    id="initialDate"
                    v-model="request.date"
                    :state="errors.length > 0 ? false : null"
                    type="date"
                  />

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row style="gap: 12px 0;">
            <b-col md="4">
              <b-button
                class="w-100"
                variant="primary"
                @click="showConfirmModal"
              >
                Liquidar
              </b-button>
            </b-col>
            <b-col md="4">
              <b-button
                type="submit"
                class="w-100"
                variant="primary"
                :disabled="invalid"
                @click="validationFormConciliation"
              >
                Conciliar Extrato
              </b-button>
            </b-col>

            <b-col md="4">
              <b-button
                type="submit"
                class="w-100"
                variant="primary"
                :disabled="invalid"
                @click="validationFormReconciliation"
              >
                Conciliar
              </b-button>
            </b-col>

          </b-row>
        </b-form>
      </validation-observer>

      <b-table
        class="mt-1 text-center table-liquidation"
        responsive="xl"
        :current-page="currentPage"
        :items="items"
        :per-page="perPage"
        :no-border-collapse="false"
        :fields="[
          { key: 'user', label: 'Nome' },
          { key: 'response_payload', label: 'Descrição' },
          { key: 'created_at', label: 'Executado Em' },
        ]"
      >
        <template #cell(created_at)="data">
          {{ data.item.created_at | dateWHour }}
        </template>

        <template #cell(user)="data">
          {{ data.item.user.name }}
        </template>
      </b-table>

      <b-pagination
        v-model="currentPage"
        :total-rows="items.length"
        :per-page="perPage"
        align="center"
        size="sm"
        class="my-1"
      />
    </b-card>
  </b-container>
</template>

<script>
import {
    BCard,
    BContainer,
    BButton,
    BTable,
    BPagination,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
} from 'bootstrap-vue';

import { DateTime } from 'luxon';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email } from '@validations';
import LiquidationService, {
    showLiquidation,
    liquidation,
    installments,
    extractConciliation,
    reconciliation,
    checkingTransaction,
} from '@/service/liquidation';
import { callLoading } from '@/service/utilities/index';

import formatValue from '@/utils/value';
import value from '@/utils/value';

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BContainer,
        BTable,
        BPagination,
        BForm,
        BFormGroup,
        BFormInput,
        ValidationProvider,
        ValidationObserver,
    },

    filters: {
        dateWHour(e) {
            return DateTime.fromISO(e).toFormat('dd/MM/yyyy hh:mm');
        },
    },

    data() {
        return {
            currentPage: 1,
            perPage: 8,
            items: [],
            request: {
                date: '',
            },
        };
    },

    created() {
        this.showLiquidation();
    },

    methods: {
        async showConfirmModalConciliation() {
            this.$bvModal
                .msgBoxConfirm('Deseja realmente conciliar extrato?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.extractConciliation();
                    }
                });
        },
        modal(message, icon = 'success') {
            this.$swal({
                icon,
                text: message,
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },

        modelErrorConciliation(message) {
            this.$swal({
                icon: 'error',
                html: message,
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },

        modalSuccessConciliation(message) {
            this.$swal({
                icon: 'success',
                html: message,
                customClass: {
                    confirmButton: 'btn btn-primary btn-modal',
                },
                buttonsStyling: false,
            });
        },

        async showLiquidation() {
            callLoading(true);
            const { status, data } = await showLiquidation();

            if (status === 200) {
                const { data: liquidationData } = data;
                this.items = liquidationData;
            } else {
                this.modal('Erro ao pegar dados', 'error');
            }
            callLoading(false);
        },

        async liquidation() {
            callLoading(true);
            const { status } = await liquidation();
            if (status === 200) {
                this.modal('Liquidação realizada com sucesso');
                this.showLiquidation();
            } else {
                this.modal('Erro ao realizar liquidação', 'error');
            }
            callLoading(false);
        },

        async installlments() {
            callLoading(true);
            const { status } = await installments(this.request.date);
            if (status === 200 || status === 201) {
                this.modal('Installments realizada com sucesso');
            } else {
                this.modal('Erro ao realizar Installments', 'error');
            }
            callLoading(false);
        },

        async extractConciliation() {
            callLoading(true);
            const { status, data } = await extractConciliation(this.request.date);
            if (status === 200) {
                const ecsNotConilicated = data.reduce((acc, item) => {
                    acc += `<p>${item.establishment} - Modalidade: ${item.extractType} - Extrato: ${formatValue(item.extractValue)} - Não conciliado: ${formatValue(item.valueNotConciliated)}</p>`;
                    return acc;
                }, '');
                this.modal();
                this.modalSuccessConciliation(`<p>Extrato da conciliação realizada com sucesso</p> ${data.length === 0 ? '' : `<p>ECs não conciliados:</p> ${ecsNotConilicated}`}`);
            } else {
                const possibleResponseInPortuguese = [
                    'Atualize parcelas para realizar conciliação',
                    'Realize a requisição do extrato da veripag',
                    'Realize a requisição da Liquidação da veripag',
                ];

                const possibleResponse = [
                    'Update installments to perform the reconciliation',
                    'Import the extract file to perform the reconciliation.',
                    'Import the liquidation file to perform the reconciliation.',
                ];

                const mapped = data.map(item => {
                    const index = possibleResponse.indexOf(item);

                    return possibleResponseInPortuguese[index];
                });
                this.modelErrorConciliation(mapped.reduce((acc, item) => {
                    acc += `<p>- ${item}.</p>`;
                    return acc;
                }, ''));
            }
            callLoading(false);
        },

        async showConfirmModalInstallments() {
            this.$bvModal
                .msgBoxConfirm('Deseja realmente realizar installments?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.installlments();
                    }
                });
        },

        async validationForm() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.showConfirmModalInstallments();
                }
            });
        },

        async validationFormConciliation() {
            this.$refs.loginValidation.validate().then(success => {
                if (success) {
                    this.showConfirmModalConciliation();
                }
            });
        },

        async validationFormReconciliation() {
            this.$refs.loginValidation.validate().then(async success => {
                if (success) {
                    const isConfirmed = await this.confirmAnAction('Deseja mesmo realizar a conciliação?');
                    if (!isConfirmed) return;

                    callLoading(true);
                    const { status, data } = await reconciliation(this.request.date);
                    callLoading(false);

                    if (status === 204) {
                        this.modalSuccess('Não houve nenhuma transação encontrada');
                    } else if (status === 200) {
                        const initialMessage = '<p> Conciliação realizada com sucesso! </p> <p> Transações que foram conciliadas: </p>';

                        const message = data.reduce((acc, item) => {
                            const totalizer = `${acc}<p>NSU: ${item.nsu} / Valor: ${value(item.value)}</p>`;

                            return totalizer;
                        }, initialMessage);
                        this.modalSuccessConciliation(message);
                    } else {
                        this.modalError('Não foi possível realizar a conciliação');
                    }
                }
            });
        },

        async validationFormCheckingTransactions() {
            this.$refs.loginValidation.validate().then(async success => {
                if (success) {
                    const isConfirmed = await this.confirmAnAction('Deseja mesmo reprocessar as transações?');
                    if (!isConfirmed) return;

                    callLoading(true);
                    checkingTransaction(this.request.date);
                    callLoading(false);

                    const status = 200;

                    if (status === 200) {
                        this.modalSuccess('Reprocessamento de transações realizado com sucesso');
                    } else {
                        this.modalError('Não foi possível reprocessar as transações');
                    }
                }
            });
        },

        async showConfirmModal() {
            this.$bvModal
                .msgBoxConfirm('Deseja realmente fazer a liquidação?', {
                    cancelVariant: 'outline-secondary',
                    centered: true,
                    okTitle: 'Sim',
                    cancelTitle: 'Não',
                })
                .then(value => {
                    if (value) {
                        this.liquidation();
                    }
                });
        },

        async doVeripag(confirmText, promise, type) {
            const isAuthorized = await this.confirmAnAction(confirmText);
            if (!isAuthorized) return;

            this.callLoading(true);
            const { status } = await promise;
            this.callLoading(false);

            if (status === 200) this.modalSuccess(`${type} realizada com sucesso`);
            else this.modalError(`${type} falhou`);
        },

        async liquidationVeripag() {
            this.doVeripag(
                'Deseja realmente realizar a liquidação da veripag?',
                LiquidationService.liquidationVeripag(this.request),
                'Liquidação Veripag',
            );
        },

        async extractVeripag() {
            this.doVeripag(
                'Deseja realmemnte requisitar o extrato da veripag?',
                LiquidationService.extractVeripag(this.request),
                'Extrato Veripag',
            );
        },
    },
};
</script>

<style>
.table-liquidation th {
  width: 333px !important;
}
</style>
